import { useUserInfoStore } from "~/stores/userInfoStore";

const exemptSections = [
    "build",
    "asset",
    "industry",
]
export const checkNodeAvailable = ( node: any, instance?: string) => {
    const userInfo = useUserInfoStore().info as any

    console.log('checkNodeAvailable userInfo', userInfo)
    console.log('checkNodeAvailable node', node)

    if (node.name == '行业'){
        return true
    }
    if(userInfo.user_type == "hm_employee"){
        if (node.name == '华美'){
            return true
        }
    }

    if (!userInfo.available_paths.find(path => path.startsWith(node.path))){
        return false
    }

    if(node.path.match(/^城市/)){
        if (!userInfo.available_cities.includes(instance)){
            return false
        }
    }

    if(node.path.match(/^品牌/)){
        if (!userInfo.available_brands.includes(instance)){
            return false
        }
    }

    if(node.path.match(/^酒店/)){
        if (!userInfo.available_hotels.includes(instance)){
            return false
        }
    }

    if(node.path.match(/^集团/)){
        if (!userInfo.available_groups.includes(instance)){
            return false
        }
    }

    return true
}